import React from 'react';

import { Main } from '@layout';
import { Seo } from '@common';
import { StyledAbout } from '@about/styles';

export default function AboutPage() {
  return (
    <Main>
      <Seo title="About" description="About Us page" />
      <StyledAbout>
        <h1>About</h1>
        <div>
          <div className="section starter">
            <h2>Tar & Partners Consulting</h2>
            <p>
            Looking for proven practices and solutions for transforming your business? Are you struggling with the latest cloud technologies? We help you to find the most appropriate solutions on top of cloud technologies to move your business processes, applications, and existing solutions to the cloud.</p>
          </div>
          <div className="section tech">
            <h2>Tech Stack</h2>
            <ul>
              <li>
                <a
                  className="gatsby"
                  href="https://azure.microsoft.com/en-us/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Azure:
                </a>{' '}
                On-premises, hybrid, multicloud, or at the edge—create secure, future-ready cloud solutions on Azure
              </li>
              <li>
                <a
                  className="emotion"
                  href="https://dynamics.microsoft.com/en-us/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dynamics 365:
                </a>{' '}
                Agility without limits.
                Adapt and innovate with a hyperconnected business—give everyone the insights and freedom to thrive by connecting your data, processes, and teams with intelligent business applications.
              </li>
              <li>
                <a
                  className="tailwind"
                  href="https://www.microsoft.com/en-us/microsoft-365"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Microsoft 365:
                </a>{' '}
                Microsoft 365 is a subscription service that lets you run your organization in the cloud while Microsoft takes care of the IT for you, managing devices, protecting against real-world threats, and providing your organization with the latest in business software.
              </li>
            </ul>
          </div>
        </div>
      </StyledAbout>
    </Main>
  );
}
